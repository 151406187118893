<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->

        <h2 class="brand-text text-primary ml-1 d-none-mobile">
          <b-img
            style="width: 35%"
            :src="require('@/assets/images/app-images/logo-superapp.svg')"
            @click="$router.push({ path: '/' })"
          />
        </h2>
        
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('Forgot Password?') }}
          </b-card-title>
          <b-card-text
            v-if="isEmail"
            class="mb-2"
          >
            {{ $t("Enter your email and we'll send you instructions to reset your password") }}
          </b-card-text>
          <b-card-text
            v-if="!isEmail"
            class="mb-2"
          >
            {{ $t("Enter your phone number and we'll send you otp to reset your password") }}
          </b-card-text>
          <b-form-group
            v-if="!isOTPSent"
            label=""
          >
            <b-form-radio-group
              v-model="forgetType"
              class="demo-inline-spacing mb-1"
            >
              <b-form-radio value="email">
                <img
                  class="icon-lable-radio"
                  :src="require('@/assets/images/app-images/icons8-mail-94.png')"
                >
              </b-form-radio>
              <b-form-radio value="mobile">
                <img
                  class="icon-lable-radio"
                  :src="require('@/assets/images/app-images/icons8-smartphone-94.png')"
                >
              </b-form-radio>
              <b-form-radio value="whatsapp">
                <img
                  class="icon-lable-radio"
                  :src="require('@/assets/images/app-images/icons8-whatsapp-94.png')"
                >
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                v-if="isEmail"
                :label="$t('Email')"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="forgetPasswordInfo.userEmail"
                    :disabled="isOTPSent"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="!isEmail"
                :label="$t('Phone Number')"
                label-for="forgot-password-phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Phone"
                  rules="required"
                >
                  <VuePhoneNumberInput
                    id="forgot-password-phone"
                    ref="countryCodeRef"
                    v-model="forgetPasswordInfo.userEmail"
                    :disabled="isOTPSent"
                    name="forgot-password-phone"
                    :state="errors.length > 0 ? false : null"
                    placeholder="+91 0000000000"
                  />
                  <!-- <b-form-input
                    id="forgot-password-phone"
                    v-model="forgetPasswordInfo.userEmail"
                    :disabled="isOTPSent"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-phone"
                    placeholder="00000000000"
                  /> -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="isOTPSent"
                :label="$t('Enter OTP')"
              >
                <b-form-input
                  v-model="forgetPasswordInfo.otp"
                  type="number"
                />
              </b-form-group>
              <b-form-group
                v-if="isOTPSent"
                :label="$t('Password')"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="forgetPasswordInfo.password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group
                v-if="isOTPSent"
                :label="$t('Confirm Password')"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="forgetPasswordInfo.confirmPassword"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{ isOTPSent ? 'Reset Password' : isEmail ? 'Send reset link' : 'Send Otp' }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: $route.meta.sharedType == 'provider' ? 'apps-provider-login' : $route.meta.sharedType == 'shop' ? 'apps-shop-login' : 'apps-login' }">
              <feather-icon icon="ChevronLeftIcon" /> {{ $t('Back to login') }}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import { BRow, BCol, BLink, BCardTitle, BCardText, BImg, BFormRadio, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupAppend, BFormRadioGroup } from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import VuePhoneNumberInput from 'vue-phone-number-input'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useLoginUi } from './useAuth'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  components: {
    // VuexyLogo,
    BRow,
    BCol,
    BLink,
    BFormRadio,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    VuePhoneNumberInput,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
      forgetPasswordInfo: {
        userEmail: '',
        otp: '',
        password: '',
        confirmPassword: '',
      },
      isOTPSent: false,
      forgetType: 'email',
    }
  },
  computed: {
    isEmail() {
      return this.forgetType === 'email'
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    hideLoader()
  },
  methods: {
    sendResetOTP() {
      const { userForgetPassword, shopForgetPassword, providerForgetPassword } = useLoginUi()
      const formData = new FormData()
      formData.append('salt_key', window.SuperApp.getters.saltKey())
      formData.append('account_type', this.forgetType === 'email' ? 'email' : 'mobile')
      if (this.forgetType === 'whatsapp') {
        formData.append('whatsapp', 1)
      }
      // eslint-disable-next-line eqeqeq
      if (this.$route.query.agent == '1') {
        formData.append('agent', 1)
      }
      formData.append(this.forgetType === 'email' ? 'email' : 'mobile', this.isEmail ? this.forgetPasswordInfo.userEmail : this.$refs.countryCodeRef.results.nationalNumber)
      if (!this.isEmail && this.$refs.countryCodeRef) {
        formData.append('country_code', `+${this.$refs.countryCodeRef.results.countryCallingCode}`)
      }
      let apiPayload = null
      if (this.$route.meta.sharedType === 'user') {
        apiPayload = userForgetPassword({
          query: this.$route.meta.sharedType,
          data: formData,
        })
      }
      if (this.$route.meta.sharedType === 'shop') {
        apiPayload = shopForgetPassword(formData)
      }
      if (this.$route.meta.sharedType === 'provider') {
        apiPayload = providerForgetPassword(formData)
      }
      showLoader()

      apiPayload
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.isOTPSent = true
            showSuccessNotification(this, 'OTP Sent successfully')
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    setNewPassword() {
      const { userResetPassword, providerResetPassword, shopResetPassword } = useLoginUi()
      const formData = new FormData()
      formData.append('salt_key', window.SuperApp.getters.saltKey())
      formData.append('account_type', this.forgetType === 'email' ? 'email' : 'mobile')
      formData.append('username', this.forgetPasswordInfo.userEmail)
      formData.append('otp', this.forgetPasswordInfo.otp)
      formData.append('password', this.forgetPasswordInfo.password)
      formData.append('password_confirmation', this.forgetPasswordInfo.confirmPassword)
      if (this.$refs.countryCodeRef) {
        formData.append('country_code', `+${this.$refs.countryCodeRef.results.countryCallingCode}`)
      }
      showLoader()
      let apiPayload = null
      if (this.$route.meta.sharedType === 'user') {
        apiPayload = userResetPassword({
          query: this.$route.meta.sharedType,
          data: formData,
        })
      }
      if (this.$route.meta.sharedType === 'shop') {
        apiPayload = shopResetPassword(formData)
      }
      if (this.$route.meta.sharedType === 'provider') {
        apiPayload = providerResetPassword(formData)
      }

      apiPayload
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, 'Password reset successfully')
            this.$router.push({
              name: 'apps-login',
            })
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.isOTPSent) {
            if (Object.keys(this.forgetPasswordInfo).filter(x => !this.forgetPasswordInfo[x]).length > 0) {
              showDangerNotification(this, 'Please enter all fields')
              return
            }
            this.setNewPassword()
          } else {
            this.sendResetOTP()
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style scoped>
.demo-inline-spacing {
  justify-content: center;
}
</style>
